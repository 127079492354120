import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="events"
export default class extends Controller {
  connect() {
    let eventTypeBasic = document.querySelector('#event_event_type_basic')
    let eventTypePremium = document.querySelector('#event_event_type_premium')
    let maxParticipants = document.querySelector('#event_max_participants');
    let waitingList = document.querySelector('#event_has_waitinglist');
    let needsConfirmation = document.querySelector('#event_needs_confirmation');
    let isPrivate = document.querySelector('#event_is_private');


    eventTypeBasic.addEventListener('change', (e) => {
      if (e.target.checked) {
        if(maxParticipants.value > 6) {
          maxParticipants.value = 6
        }
        let children = maxParticipants.children;
        for (let i = 0; i < children.length; i++) {
          let child = children[i];
          child.disabled = i >= 6;
        }
        waitingList.disabled = true;
        waitingList.checked = false;
        needsConfirmation.disabled = true;
        needsConfirmation.checked = false;
        isPrivate.disabled = true;
        isPrivate.checked = false;
      }
    })

    eventTypePremium.addEventListener('change', (e) => {
      if (e.target.checked) {
        let children = maxParticipants.children;
        for (let i = 0; i < children.length; i++) {
          let child = children[i];
          child.disabled = false;
        }
        waitingList.disabled = false;
        needsConfirmation.disabled = false;
        isPrivate.disabled = false;
      }
    })
  }
}
